
import { mapState } from 'vuex'
import DataTable from '~/mixins/dataTable'

export default {
    name: 'DesktopNav',
    mixins: [DataTable],
    props: {
        links: {
            type: Object,
            default: () => {},
        },
        languages: {
            type: Array,
            default: () => [],
        },
        token: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        openCard: [],
        v: true,
        windowSize: {
            x: 0,
            y: 0,
        },
    }),
    computed: {
        ...mapState({
            player: (state) => state.player.player,
            isLogin: (state) => state.settings.isLogin,
            totalBalanceLoading: (state) => state.player.totalBalanceLoading,
        }),
    },
    mounted() {
        if (this.$cookie.get('token')) {
            if (this.player.player_id) return
            this.$store.dispatch('player/profile')
            this.$store.dispatch('settings/toggleIsLogin', true)
            this.$store.dispatch('player/totalBalance')
        }
    },
    methods: {
        getCurrentLocale(localeCode) {
            const currentLocaleData = this.languages.filter(
                (el) => el.code === localeCode
            )
            if (currentLocaleData.length > 0) {
                return currentLocaleData[0]?.flag_icon
            }
            return null
        },
        onLogout() {
            this.logout()
        },
        onResize() {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },
    },
}
