import { render, staticRenderFns } from "./Nav.vue?vue&type=template&id=1a66ebb9"
import script from "./Nav.vue?vue&type=script&lang=js"
export * from "./Nav.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LanguageSwitcher: require('/var/app/components/LanguageSwitcher.vue').default,NavContainer: require('/var/app/components/NavContainer.vue').default,NuxtImage: require('/var/app/components/NuxtImage.vue').default,Menubar: require('/var/app/components/Menubar.vue').default,CopyButton: require('/var/app/components/CopyButton.vue').default})
