
import { mapGetters } from 'vuex'
export default {
    name: 'MenubarComponent',
    data: () => ({
        openCard: [],
        v: true,
    }),
    computed: {
        ...mapGetters({
            menubars: 'cms/menubars',
        }),
    },
}
