
import { mapGetters } from 'vuex'
export default {
    name: 'MaintenanceComponent',
    data: () => ({
        dialog: false,
    }),
    computed: {
        ...mapGetters({
            maintenance: 'theme2/settings/maintenance',
        }),
    },
    async mounted() {
        const result = await this.$store.dispatch(
            'theme2/settings/fetchMaintenance'
        )
        if (result && result.status) {
            this.dialog = true
            if (this.$cookie.get('token')) this.logout()
        }
    },
}
