
import { mapGetters, mapState } from 'vuex'
import GlobalMixins from '~/mixins/globalMixins'
export default {
    name: 'LanguageSwitcher',
    mixins: [GlobalMixins],
    props: {
        languages: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        ...mapState({
            supportCountryAndLanguage: (state) =>
                state.settings.supportCountryAndLanguage,
        }),
        ...mapGetters({
            theme2Languages: 'theme2/settings/language',
        }),
    },
    methods: {
        isActiveLanguage(item) {
            return item?.supported_languages?.filter(
                (el) => el?.is_active || el?.is_default
            )
        },
    },
}
