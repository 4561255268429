
export default {
    name: 'CopyButton',
    props: {
        text: {
            type: [String, Number],
            default: '',
        },
    },
    methods: {
        copyText() {
            this.copy(this.text, false)
        },
    },
}
